<template>
  <ik-data-table
    :entity-name="$t('FreeInformationBon')"
    :icon="'mdi-format-list-bulleted'"

    :model="model"
    :use-select="true"
  />
</template>

<script>
  import FreeInfoSheet from '@/model/print/FreeInfoSheet'
  import { IkDataTable } from 'metaflow-js'

  export default {
    name: 'FreeInfoSheetVue',
    components: { IkDataTable },
    data () {
      return {
        model: FreeInfoSheet,
      }
    },
    methods: {},
  }
</script>

<style scoped>

</style>
