import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import GlobalSettings from '@/GlobalSettings'
import StringEntity from '@/model/common/StringEntity'

const entity = {
  langs: {
    displayName: 'info',
    type: IKDataEntity.Types.Group,
    formConfig: {
      groupName: 'name',
      default: GlobalSettings.supportLang,
      childLabelKey: 'lang',
      type: {},
    },
    tableConfig: {
      displayCondition: (item) => {
        return item.lang.toUpperCase() === GlobalSettings.getLang().toUpperCase()
      },
      displayChild: (key) => {
        return key === 'name'
      },
    },
    childKey: ['name'],
    labelKey: 'lang',
    children: [
      StringEntity, StringEntity, StringEntity,
    ],
  },
  sort: {
    type: IKDataEntity.Types.Integer,
  },

}

const config = {

  add: function (item) {
    return hillo.post('FreeInformation.php?op=add', {
      ...item,
      langs: JSON.stringify(item.langs),
    })
  },
  remove: function (id) {
    // -9/-10 保留选项
    if (id === -9 || id === -10) {
      return false
    } else {
      return hillo.post('FreeInformation.php?op=delete', { id: id })
    }
  },
  edit: function (item) {
    // -9/-10 保留选项
    if (item.id === -9 || item.id === -10) {
      return false
    } else {
      // console.log('edit item', item)
      return hillo.postWithUploadFile('FreeInformation.php?op=update', {
        ...item,
        langs: JSON.stringify(item.langs),
      })
    }
  },
  load: async function (filter) {
    return (await hillo.get('FreeInformation.php', filter))
      .content
  },

  // search
}

export default IKDataEntity.ModelFactory(entity, config)
